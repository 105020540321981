<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center" v-if="!showReset">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm @submit.prevent="forgotPassword()" method="POST">
                <h4>Lupa Password</h4>
                <p>Masukkan Email</p>

                <CInput
                  :invalid-feedback="validatorForgotPassword.email"
                  :is-valid="validatorForgotPassword.email ? false : true"
                  v-model="formForgotPassword.email"
                  placeholder="Email"
                  autocomplete="email"
                  prepend="@"
                />
                <p>OTP akan dikirimkan ke email anda</p>
                <CButton type="submit" color="success" block>Kirim OTP</CButton>
              </CForm>
            </CCardBody>
            <CCardFooter class="p-4">
              <CRow>
                <CCol>
                  <router-link to="/auth/login">
                    <CButton block variant="outline" color="primary">
                      Ke halaman login
                    </CButton>
                  </router-link>
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>

      <CRow class="justify-content-center" v-if="showReset">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm @submit.prevent="resetPassword()" method="POST">
                <h4>Ubah Password</h4>
                <label for="">OTP</label>
                <CInput
                  placeholder="OTP"
                  type="number"
                  :isValid="validatorResetPassword.otp ? false : true"
                  :invalid-feedback="validatorResetPassword.otp"
                  v-model="formResetPassword.otp"
                >
                  <template #prepend-content
                    ><CIcon name="cil-screen-smartphone"
                  /></template>
                </CInput>
                <label for="">Password Baru</label>
                <CInput
                  placeholder="Password Baru"
                  type="password"
                  autocomplete="curent-password"
                  :isValid="validatorResetPassword.password ? false : true"
                  :invalid-feedback="validatorResetPassword.password"
                  v-model="formResetPassword.password"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <label for="">Ulangi Password Baru</label>
                <CInput
                  placeholder="Ulangi Password Baru"
                  type="password"
                  :isValid="
                    validatorResetPassword.password_confirmation ? false : true
                  "
                  :invalid-feedback="
                    validatorResetPassword.password_confirmation
                  "
                  v-model="formResetPassword.password_confirmation"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <CButton type="submit" color="success" block
                  >Ubah Password</CButton
                >
              </CForm>
            </CCardBody>
            <CCardFooter class="p-4">
              <CRow>
                <CCol>
                  <router-link to="/auth/login">
                    <CButton block variant="outline" color="primary">
                      Ke halaman login
                    </CButton>
                  </router-link>
                </CCol>
              </CRow>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from "../../apis/api";
export default {
  name: "Register",
  data() {
    return {
      showReset: false,
      formForgotPassword: {
        email: "",
      },
      validatorForgotPassword: {
        email: "",
      },

      formResetPassword: {
        otp: "",
        password: "",
        password_confirmation: "",
      },
      validatorResetPassword: {
        otp: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  methods: {
    resetValidator() {
      this.validatorForgotPassword.email = "";
      this.validatorResetPassword.password = "";
      this.validatorResetPassword.password_confirmation = "";
    },
    async forgotPassword() {
      let loader = await this.$loading.show();
      this.resetValidator();
      try {
        await axios.post("/user/forgot_password", {
          email: this.formForgotPassword.email,
        });

        await this.$notify({
          type: "success",
          text: "Masukkan OTP yang telah dikirim ke email",
        });
        this.showReset = true;
      } catch (error) {
        if (error.response.data) {
          const errors = error.response.data.errors;
          if (errors.email) {
            this.validatorForgotPassword.email = errors.email.join(" & ");
          }
          if (error.response.data.message) {
            await this.$notify({
              type: "error",
              text: error.response.data.message,
            });
          }
        }
      }
      await loader.hide();
    },
    async resetPassword() {
      let loader = await this.$loading.show();

      this.resetValidator();
      try {
        await axios.post("/user/reset_password", {
          email: this.formForgotPassword.email,
          otp: this.formResetPassword.otp,
          password: this.formResetPassword.password,
          password_confirmation: this.formResetPassword.password_confirmation,
        });
        await this.$notify({
          type: "success",
          text: "Password berhasil diubah",
        });
        this.$router.push("/auth/login");
      } catch (error) {
        if (error.response.data) {
          const errors = error.response.data.errors;
          if (errors.otp) {
            this.validatorResetPassword.otp = errors.otp.join(" & ");
          }
          if (errors.password) {
            this.validatorResetPassword.password = errors.password.join(" & ");
          }
          if (errors.password_confirmation) {
            this.validatorResetPassword.password_confirmation =
              errors.password_confirmation.join(" & ");
          }
          if (error.response.data.message) {
            await this.$notify({
              type: "error",
              text: error.response.data.message,
            });
          }
        }
      }
      await loader.hide();
    },
  },
};
</script>
